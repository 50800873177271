// Media Sizes
$xs-screen: 200px;
$sm-screen: 280px;
$md-screen: 744px;
$lg-screen: 1280px;

$nav-bar-height: 270px;

// colors
$primary-color-light: #29a261;
$primary-font-color-light: #5c696f;
$secondary-color-light: #f1f4f6;
