.chat_view {
  display: flex;
  flex: 1;
  overflow: initial;
  flex-direction: column;
  margin: 20px 30px 20px 30px;

  // Mobile styles
  @media (max-width: $md-screen) {
    margin: 0px;
  }
}

.chat {
  flex: 1;
  background-color: #fff;

  .menu_item__web {
    background-color: $secondary-color-light;
    border-radius: 8px !important;
    width: 40px;
    height: 40px;
    display: flex;

    cursor: pointer;

    align-items: center;
    justify-content: center;
    left: 45px;
    top: 35px;
    position: absolute;

    // Mobile styles
    @media (max-width: $md-screen) {
      display: none;
    }
  }

  .menu_item__tab {
    background-color: $secondary-color-light;
    border-radius: 8px !important;
    display: flex;
    width: 65px;
    height: auto;
    justify-content: center;
    align-items: center;

    // Mobile styles
    @media (min-width: $lg-screen) {
      display: none;
    }
  }

  .messages_container {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .message {
      max-width: 70%;
      padding: 8px;
      margin: 5px;
      border-radius: 8px;
    }

    .user {
      align-self: flex-end;
      background-color: #ddd;
      padding: 10px 15px 10px 15px;
    }

    .other {
      align-self: flex-start;
      background-color: $secondary-color-light;
      padding: 10px 15px 10px 15px;
    }
  }

  .chat_tabs {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (max-width: $lg-screen) {
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      gap: 10px;
    }

    @media (max-width: $md-screen) {
      justify-content: space-between;
      gap: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .new_chat__container {
    display: flex;
    justify-content: center;
    gap: 10px;

    cursor: pointer;

    span {
      color: $primary-font-color-light;
    }
  }
}

.tone_settings__form {
  .slider__container {
    padding: 10px;
    width: 100%;

    background-color: $secondary-color-light;
    border-radius: 10px;

    .ant-slider-track {
      background-color: #b7bdc1 !important;
    }

    .ant-slider-handle::after {
      box-shadow: 0 0 0 2px #b7bdc1 !important;
    }

    .options {
      display: flex;
      justify-content: space-between;
    }
  }
}

.chat_history__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    max-width: 300px;
  }

  cursor: pointer;

  .more {
    gap: 10px;
    display: flex;
  }
}

.history_drawer {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search_input {
    margin-top: 20px;
    margin-bottom: 5px;
    background-color: $secondary-color-light;

    input {
      border: none !important;
    }

    &:focus-within {
      border: 0.5px solid rgba(96, 191, 50, 0.6) !important;
      box-shadow: 0px 0px 8px 0px rgba(96, 191, 50, 0.7) !important;
      backdrop-filter: blur(2px) !important;
    }

    &:hover {
      border: 0.5px solid rgba(96, 191, 50, 0.6) !important;
      box-shadow: 0px 0px 8px 0px rgba(96, 191, 50, 0.7) !important;
      backdrop-filter: blur(2px) !important;
    }
  }
}
