.ant-input:focus-within {
  border: 0.5px solid rgba(96, 191, 50, 0.6) !important;
  box-shadow: 0px 0px 8px 0px rgba(96, 191, 50, 0.7) !important;
  backdrop-filter: blur(2px) !important;
}

.ant-input:hover {
  border-color: hwb(100 20% 25%) !important;
}

.form_item__col {
}

.ant-form-item-explain-error {
  margin-top: 8px;
}

.ant-input-number {
  width: 100%;
}
