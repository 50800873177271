.input_form {
  display: flex;
  gap: 10px;
  align-items: center;

  .chat_input {
    background-color: $secondary-color-light;
    display: flex;
    flex: 1;
    min-height: 50px;
  }

  .send_btn {
    border-radius: 10px !important;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $primary-color-light;
  }

  .chat_input::placeholder {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
  }
}
