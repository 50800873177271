.hidden {
  display: none !important;
}

.custom_divider {
  border-color: #b5b5b5;
}

.dropdown_menu {
  background-color: $secondary-color-light;
  padding: 10px;
  border-radius: 10px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}
