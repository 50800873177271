.custom_tab {
  @media (max-width: $lg-screen) {
    justify-content: center;
    display: flex;
  }

  .ant-radio-group {
    background-color: $secondary-color-light !important;
    padding: 10px;
    gap: 15px !important;
    display: flex;

    border-radius: 10px;

    @media (max-width: $lg-screen) {
      width: 100% !important;
    }

  }

  .tab {
    width: 247px;
    height: 38px;

    @media (max-width: $lg-screen) {
      // width: auto !important;
      width: -webkit-fill-available !important;
    }
  }

  .ant-radio-button-wrapper {
    height: 38px;
    text-align: center;

    border-radius: 10px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;

    background-color: $secondary-color-light;

    @media (max-width: 330px) {
      width: 170px;
      height: auto;
    }

    @media (max-width: $lg-screen) {
      padding: 10px !important;
    }

    span {
      color: $primary-font-color-light;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 600 !important;

      @media (max-width: $md-screen) {
        font-size: 10px !important;
      }
    }
  }

  .ant-radio-button-wrapper::before {
    display: none !important;
  }

  .ant-radio-button {
    border-radius: 6px 0px 0px 6px;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #fff !important;
  }
}
