.icon_btn {
  background-color: $secondary-color-light;
  border-radius: 8px !important;
  width: 40px;
  height: 40px;
  display: flex;

  cursor: pointer;

  align-items: center;
  justify-content: center;
  border: none !important;
}

.ant-btn:hover {
  // opacity: 0.9;
  background-color: $primary-color-light !important;
  opacity: 0.8;
  border: none !important;
}

.btn {
  background-color: $primary-color-light !important;
}
